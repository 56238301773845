




















































































































































































import { Component, Vue, Emit } from 'vue-property-decorator';
import VeeValidate from '../../plugins/vee-validate';
import VueRouter from 'vue-router';
import AccountForm from '../../plugins/AccountForm';

@Component({
  mixins: [ VeeValidate ],
})
export default class MailMagazineTerm extends Vue {
  private errorMessage: boolean = false;
  private fromPath: any = '';
  private get agree(): string {
    const $parent: any = this.$parent;
    return $parent.agree;
  }
  private set agree(value: string) {
    this.inputAgree(value);
  }
  @Emit()
  private inputAgree(value: string) {
    const $parent: any = this.$parent;
    $parent.setAgree(value);
  }
  private onSubmit() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then((result: boolean) => {
      if (result) {
        window.location.href = 'https://a19.hm-f.jp/index.php?action=R1&a=566&g=18&f=36&fa_code=78ec259a1b9b2de89950b3e0171408db';
        return true;
      } else {
        this.errorMessage = true;
        // Vue.nextTick()
        // .then(() => {
        //   const errorDome: any = this.$refs.errorMessage;
        //   window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth',
        //   });
        // });
        return false;
      }
    });
  }
  private beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    const f: any = from;
    const fromPath = f.path;
    next((component: any) => {
      component.fromPath = fromPath;
    });
  }
}
